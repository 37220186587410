<template>
    <main data-aos="fade">
      <section class="slider-container" @click="handleClick" @keydown="handleKeyDown">
        <div class="slider-content">
          <img v-if="currentItem.type === 'image'" 
               :src="require(`@/assets/Fashionkilla/${currentItem.src}`)" 
               :alt="currentItem.alt" 
               :width="currentItem.width" 
               :height="currentItem.height" 
               :data-aos="currentItem.data"
               :class="['slider-image', currentItem.class]" />
          <iframe v-else 
                  :src="currentItem.src" 
                  class="video-content" 
                  :width="currentItem.width" 
                  :height="currentItem.height" 
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                  allowfullscreen
                  ref="youtubeIframe">
          </iframe>
          <div class="textDesc">
            <p class="image-description gras maj">
              {{ currentItem.description }} <sup>{{ currentIndex + 1 }}/{{ totalItems }}</sup>
            </p>
            <p class="image-description2 gras maj">
              {{ currentItem.description2 }}
            </p>
          </div>
        </div>
      </section>
    </main>
  </template>
  
  
  <script>
  /* global YT */
  import AOS from 'aos';
  import 'aos/dist/aos.css';
  
  export default {
    data() {
      return {
        currentIndex: 0,
        items: [
          { type: 'image', src: 'HoodieBordeaux.png',alt: 'BOY IS A GUN', description: 'FASHION KILLA', description2: 'CGI, GRAPHIC DESIGN & CLOTHS', width: '1080px', height: '900px',  },
          { type: 'image', src: 'Boy.jpg',alt: 'BOY IS A GUN', description: 'FASHION KILLA', description2: 'CGI, GRAPHIC DESIGN & CLOTHS', width: '850px', height: '900px', class:'resize-img' },
          { type: 'image', src: 'HoodiRed.png',alt: 'BOY IS A GUN', description: 'FASHION KILLA', description2: 'CGI, GRAPHIC DESIGN & CLOTHS', width: '1080px', height: '900px', },
          { type: 'image', src: 'BOYisaGUNV3.jpg', alt: 'BOY IS A GUN', description: 'FASHION KILLA', description2: 'CGI, GRAPHIC DESIGN & CLOTHS', width: '850px', height: '900px', class:'resize-img' },
          { type: 'image', src: 'HoodieBlack.png',alt: 'BOY IS A GUN', description: 'FASHION KILLA', description2: 'CGI, GRAPHIC DESIGN & CLOTHS', width: '1080px', height: '900px',  },
          { type: 'image', src: 'BOYisaGUNV2.jpg', alt: 'BOY IS A GUN', description: 'FASHION KILLA', description2: 'CGI, GRAPHIC DESIGN & CLOTHS', width: '850px', height: '900px', class:'resize-img' },
          { type: 'image', src: 'HoodieGrey.png',alt: 'BOY IS A GUN', description: 'FASHION KILLA', description2: 'CGI, GRAPHIC DESIGN & CLOTHS', width: '1080px', height: '900px', },
          { type: 'image', src: 'BOYisaGUNV7.jpg',alt: 'BOY IS A GUN', description: 'FASHION KILLA', description2: 'CGI, GRAPHIC DESIGN & CLOTHS', width: '850px', height: '900px', class:'resize-img' },
          { type: 'image', src: 'GUN_BLUR.jpg',alt: 'BOY IS A GUN', description: 'FASHION KILLA', description2: 'CGI, GRAPHIC DESIGN & CLOTHS', width: '850px', height: '900px', class:'resize-img' },
          { type: 'image', src: 'Planche.png',alt: 'BOY IS A GUN', description: 'FASHION KILLA', description2: 'CGI, GRAPHIC DESIGN & CLOTHS', width: '1080px', height: '900px', },

        ],
        intervalId: null,
        videoTimeoutId: null,
        player: null
      };
    },
    computed: {
      currentItem() {
        return this.items[this.currentIndex];
      },
      totalItems() {
        return this.items.length;
      }
    },
    mounted() {
      AOS.init();
      this.startAutoScroll();
      window.addEventListener('keydown', this.handleKeyDown);
      window.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady;
    },
    updated() {
      AOS.refresh();
    },
    beforeDestroy() {
      this.stopAutoScroll();
      window.removeEventListener('keydown', this.handleKeyDown);
    },
    watch: {
      currentIndex(newIndex, oldIndex) {
        if (this.items[newIndex].type === 'video') {
          this.stopAutoScroll();
          this.setupVideoTimeout();
          this.initializeYouTubePlayer();
        } else if (this.items[oldIndex].type === 'video') {
          this.clearVideoTimeout();
          this.startAutoScroll();
        }
      }
    },
    methods: {
      handleClick(event) {
        const screenWidth = window.innerWidth;
        const clickPosition = event.clientX;
        if (clickPosition < screenWidth / 2) {
          this.prevItem();
        } else {
          this.nextItem();
        }
      },
      handleKeyDown(event) {
        if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
          this.prevItem();
        } else if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
          this.nextItem();
        }
      },
      nextItem() {
        this.currentIndex = (this.currentIndex + 1) % this.totalItems;
      },
      prevItem() {
        this.currentIndex = (this.currentIndex - 1 + this.totalItems) % this.totalItems;
      },
      startAutoScroll() {
        if (!this.intervalId) {
          this.intervalId = setInterval(() => {
            this.nextItem();
          }, 30000);
        }
      },
      stopAutoScroll() {
        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      },
      setupVideoTimeout() {
        this.clearVideoTimeout();
        this.videoTimeoutId = setTimeout(() => {
          this.nextItem();
        }, 35000);
      },
      clearVideoTimeout() {
        if (this.videoTimeoutId) {
          clearTimeout(this.videoTimeoutId);
          this.videoTimeoutId = null;
        }
      },
      onYouTubeIframeAPIReady() {
        const videoElement = this.$refs.youtubeIframe;
        if (videoElement) {
          this.player = new YT.Player(videoElement, {
            events: {
              'onStateChange': this.onPlayerStateChange
            }
          });
        }
      },
      onPlayerStateChange(event) {
        if (event.data === YT.PlayerState.PLAYING) {
          this.stopAutoScroll();
          this.clearVideoTimeout();
        } else if (event.data === YT.PlayerState.PAUSED || event.data === YT.PlayerState.ENDED) {
          this.setupVideoTimeout();
        }
      },
      initializeYouTubePlayer() {
        if (typeof YT === 'undefined' || typeof YT.Player === 'undefined') {
          const tag = document.createElement('script');
          tag.src = 'https://www.youtube.com/iframe_api';
          const firstScriptTag = document.getElementsByTagName('script')[0];
          firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        } else {
          this.onYouTubeIframeAPIReady();
        }
      }
    }
  };
  </script>
  
  
  <style scoped>
  .slider-container {
   
    width: 100svw;
    height: 100svh;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* overflow-x: hidden; */
    /* margin: 0.5px; */
    padding: 0;
    flex-direction: column;
  }
  
  .slider-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .slider-image {
    max-width: 100svw;
    max-height: 90svh;
    object-fit: contain;
  }
  
  .video-content {
    z-index: 4;
    max-width: 100svw;
    max-width: 100%;
    max-height: 80vh;
    max-height: 80dvh;
    max-height: 80svh;
    position: fixed;
    top: 5%;
  }
  
  .textDesc {
    display: flex;
    justify-content: space-between;
    /* padding: 1%; */
    width: 100%;
    position: absolute;
    bottom: 0.8%;
  }
  
  .image-description {
    margin-top: 10px;
    text-align: center;
  }
  
  .image-description2 {
    margin-top: 10px;
    text-align: center;
  }
  
  .slider-container::before,
  .slider-container::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
  }
  
  .slider-container::before {
    left: 0;
  }
  
  .slider-container::after {
    right: 0;
  }
  
  .slider-container:hover::before {
    cursor: w-resize;
  }
  
  .slider-container:hover::after {
    cursor: e-resize;
  }
  
  .indexItem{
    font-size: 0,05rem;
  }
  
  @media (max-width: 1600px) {
    .resize-img{
      height: 70dvh;
  
    }
  
  }
  /* Media queries pour tablettes */
  @media (max-width: 1024px) {
    .slider-image,
    .video-content {
      max-height: 70vh;
    }
  }
  
  /* Media queries pour smartphones */
  
  /* Media queries pour les appareils tactiles */
  @media (hover: none) and (max-width: 850px) {
    .slider-image {
      max-height: 60vh;
    }
    .video-content {
      max-height: 40vh;
    }
    .textDesc {
      /* position: inherit; */
    }
  }
  
  @media (max-width: 768px) {
    .resize-img{
      width: 95dvw;
    }
    .slider-image {
      max-height: 60vh;
    }
    .video-content {
      max-height: 50vh;
    }
    .image-description, .image-description2{
  font-size: 0.7rem;
    }
  }
  
  @media (max-width: 550px) {
    .video-content {
      max-height: 30vh;
    }
  }
  
  /* heig query */
  @media (max-height: 668px) {
    .image-description, .image-description2{
  font-size: 0.6rem;}
      .slider-image {
      max-height: 80vh;
      max-height: 80dvh;
      max-width: 100vw;
      max-width: 100dvw;
    }
    .video-content {
      max-height: 50vh;
    }
  }
  
  @media (max-height: 450px) {
    .slider-image {
      max-height: 70vh;
    }
    .video-content {
      max-height: 80vh;
    }
  }
  </style>
  